<template>
  <div class="page">
    <Navbar title="基础资料" type="SMP" :callback="back" />
    <van-tabs v-model="active" border="true" :color="COLOR_M">
      <van-tab title="工作匹配" name="RCT">
        <Recruit ref="recruit" :person="personCode" />
      </van-tab>
      <van-tab title="证书匹配" name="TRA"
        ><div class="no-tips">
          <van-icon name="info-o" />&nbsp;暂未开通匹配服务
        </div></van-tab
      >
      <van-tab title="上学匹配" name="ERO"
        ><div class="no-tips">
          <van-icon name="info-o" />&nbsp;暂未开通匹配服务
        </div></van-tab
      >
      <van-tab title="婚恋匹配" name="LOV"
        ><div class="no-tips">
          <van-icon name="info-o" />&nbsp;暂未开通匹配服务
        </div></van-tab
      >
    </van-tabs>
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import Navbar from '../../common/Navbar.vue'
import Loading from '../../common/Loading.vue'
import Recruit from '../match/Recruit.vue'
import { Tabs, Tab, Icon } from 'vant'
export default {
  components: {
    Navbar: Navbar,
    Loading: Loading,
    Recruit: Recruit,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Icon.name]: Icon
  },
  data () {
    return {
      active: 'RCT',
      personCode: '',
      loadingShow: false
    }
  },
  mounted () {
    var query = this.$route.query
    this.personCode = query.personCode
  },
  methods: {
    initRecruit () {
      this.$refs.recruit.initMatch(this.personCode)
    },
    back () {
      var url = window.sessionStorage.getItem(window.const.global.PEOPLE_PERSON_MATCH_BACK)
      window.location.href = url
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  .no-tips {
    margin-top: 50px;
  }
}
</style>
